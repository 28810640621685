<template>
  <div class="faq">
    <div class="desc">
      登录提示付费的填下下面的表格，其中 github登录名是github.com/xxxxx
      的xxxx这一部分，也就是登录后头像下方括号里的内容（这个是最准的）。大家如实填下这个表单：https://airtable.com/shrM0ri4uAGvEv9Tj
      <br />我后面会逐个给大家添加，大家不要急
      （一般几个小时）名单是群主手动更新，因此需要时间。<br />
      如果登记了很久还是进不去，请检查自己是否在名单，名单地址：https://github.com/leetcode-pp/leetcode-pp-node/blob/main/config/users-{{
        season
      }}.js ，（Github 用户名不区分大小写）且填写无误（另外确保你的名字不在
      unknowns 里）。如果填错了，修改后同步我修改之前的和之后的。
      不在名单就别想了，肯定进不去。<br /><br />
      本期打卡规则如下: <br />

      1. 在打卡标签下的题目描述下方留言打卡 <br />
      2. 当天的打卡题当天comment，记一次打卡，不需要问时差问题，自己安排。<br />
      3. 超过连续七天未打卡的，将被脚本移出访问权限，但不会被踢出微信群。<br /><br />

      开始学习之前请确保自己: <br />

      - 熟悉和了解github的基本使用<br />
      - 熟悉和了解markdown的基本使用<br />

      自测问题<br />

      1. 我能快速登陆我的github账号吗？<br />

      2. 我的github username是什么？<br />

      3. repo是什么？什么是public repo和private
      repo？为什么我访问一个repo会出现404，但是其他人就能访问？（加分：建立一个自己的力扣打卡repo）<br />

      4. 一个repo的code在哪里？<br />

      5. 一个repo的issue在哪里？<br />

      6. markdown是什么？<br />

      7. 怎么样在markdown里添加标题？<br />

      8. 怎么样在markdown里添加带有语言特征的code
      block？（比如我写c++，怎么样让markdown里的这段code按照c++语法高亮呢）<br />

      9. 怎么在md里添加超链接？<br />

      10.
      加分：创建自己喜欢的刷题markdown笔记模板，模板内可以包含题目名称，题目链接，题目思路，题目解体的code，时间和空间复杂度。<br /><br />
    </div>
    以下为常见问题及答案：<br />

    Q: 如何使用 chatgpt 翻译和讲解代码?<br />
    A: 这个科学工具自带了 chatgpt backup solution， 里面有 token
    可以直接用。https://github.com/fe-lucifer/fanqiang <br /><br />

    Q：一直转圈圈，提示 Network Error 或者 timeout of xxxx 是怎么回事？<br />
    A：1. 确保已经科学上网，如果没有，可以看下这个
    https://github.com/fe-lucifer/fanqiang 2.
    确保将当前网站加入到代理规则中（一般常见的代理软件都会将非常见国内的网站都使用代理，所以如果你使用的是常见的代理软件应该不会有这个问题），如果实在不会加规则，可以尝试使用全局代理。
    <br /><br />

    Q：提示没有登录或者付费是怎么回事？<br />
    A：顾名思义，它的意思是你没有登录或者没有付费。如果你确认已经付费，请检查群公告中的表格是否已经添加了你且<b>名字拼接正确</b>，其中
    githubid 是github.com/xxxxx 的xxxx这一部分。如果全部检查没有问题，
    可以尝试刷新页面后再试。 当然还有一种可能就是你连续 7
    天没有打卡，这个时候想要恢复必须交 10 块钱才可以哦。所以<b
      >划重点，七天一定要至少打一次卡</b
    ><br />
    <br />
    Q：Github 收到很多邮件，怎么取消？<br />
    A：参考 https://www.bpteach.com/knowledge-base/1047564/ <br /><br />
    <!-- Q：仓库在哪里？怎么进？点击 404 如何处理？<br />
    A：仓库在 91
    天学算法官网中的打卡模块中可以看到，官网地址：https://leetcode-solution.cn/91。<b
      >如果你提示 404， 请检查你的邮箱是否有一个邀请邮件（是邀请加入 team
      的邮件，不是 91algo app
      使用的通知邮件）。如果有，请接收之。如果没有，请尝试更换 Github
      邮箱。以上方法都不行，再联系微信群管理员（小然子）。</b
    ><br /><br /> -->
    Q：零基础人群可以学习吗？<br />
    A：只要掌握一门编程语言就可以学习。<br /><br />
    Q：课程是用什么语言教学的？<br />
    A：Java， Python，JS，CPP
    都可能，不过算法涉及到的语言都比较基础，即使不了解，也完全可以学习。另外算法重要的是思想，
    语言不重要，思路理解了比什么都重要。 <br /><br />
    Q：讲义和题解能够观看多久？<br />
    A:为了有效督促学习，如果大家被违反规则被清退（<b>规则包括长时间群里闲聊和连续七天不打卡</b>），则不可以继续观看，否则可以长期观看。<b
      >活动结束后将无法访问网站，不过我们会提供电子书供大家长期观看</b
    ><br /><br />
    Q：我该怎么学习？<br />
    A：每一个小节开始之前都会提前把讲义公布到这里，大家可以关注一下，提前预习。每天都会有一道题，第二天会公布前一天的题解，所有<b>打卡和题解</b>都可以在这里查看。另外我还介绍了一些学习方法，
    具体见先导篇的视频。<br /><br />
    Q：我该怎么打卡？<br />
    A：打卡只需要在打卡标签的题目下方下留言即可。注意当天的卡必须当天提交才算打卡成功，打卡后可以在我的标签查看（有一个小时左右的延迟）。注意格式要求。格式模板在先导篇哦~
    <br /><br />
    Q: 只能当天打卡吗？ 如果一周补打卡算吗？<br />
    A:
    是的。必须当天才能打卡，比如第七天的题，那么只有那一天打卡才算打卡成功。<br />
    <!-- 如果你连续打卡七天可以获取一张补签卡，补签卡是虚拟计算用的（不会实际发放），每月结束我们会统计当月满勤的同学，如果你不满勤，但是使用补签卡后满勤也是可以的。也就是说必须当天打卡，需要补卡的必须有补签卡，补签卡的获得方式是连续打卡七天。<br /><br /> -->
    Q：微信群的作用是什么？<br />
    A：重要信息都在群公告和仓库，大家注意这两个信息渠道即可。微信群用来交流一下简单的，容易回答的问题。一些复杂的问题大家可以提
    issue。 <br /><br />
    Q：虽然你这么说，但是我还是不想错过微信群的重要信息怎么办？<br />
    A：重要信息在仓库和群公告。如果大家还是怕错过重要群信息，可以按如下操作，仅看群主即可。
    首先点击微信群右上角的按钮进入群设置，并翻到最下方。
    点击“查找聊天内容”，然后进入“按群成员查找”。 找到需要查找聊天记录的人，比如
    lucifer。微信新版本可以关注群成员，大家不妨忽略群消息并关注一下群主。<br /><br />
  </div>
</template>

<script>
import { season } from '../../config/index'

export default {
  data() {
    return {
      season
    }
  }
}
</script>

<style lang="less" scoped>
.faq {
  text-align: left;
}
.desc {
  margin-bottom: 20px;
  border-radius: 15px;
  padding: 30px;
  color: #e3e3e3;
  background: rgba(0, 0, 0, 0.7);
}
</style>
